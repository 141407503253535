import React from "react";

import LessonLayout from "~/layouts/lesson";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Poll from "~/components/poll";

import POLL_RESPONSES from "~/consts/poll-responses";


const Page = () => {
  return (
      <LessonLayout
        current_step={1}
        lesson={"Lesson 2"}
        color={"ms"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 1</H2>

          <p>Suppose you do an internet search that results in a lot of hits. Can you trust the information found on the sites? Researchers have investigated this question. What do you think they found? Give your opinion by voting on the following question.</p>

          <Poll color="ms" responses={POLL_RESPONSES} question="How accurate is health information found on the internet?" />

          <p>After you vote, wait for a class discussion led by your teacher.</p>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
